<template>
  <div>
    <img class="nav" src="../../assets/images/InviteFriends-navImg.png" @click="leftReturn" >
    <div id="url">
      <img :src="url">
    </div>
    <common-empty v-if="!url"></common-empty>
  </div>
</template>

<script>
import {queryBaseData} from "../../getData/getData";
import {checkAndroid, checkIOS, responseUtil} from "../../libs/rongxunUtil";
//调用android关闭页面方法*******begin*********
function backToAndroid(){
  window.himi.closeWindow();
}
//调用android关闭页面方法*******end***********

/********判断H5页面是否在web容器中 start*********/
function openInWebview () {/* 返回true或false; */
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
    return false;
  } else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
    return false;
  } else if (ua.match(/WeiBo/i) == "weibo") {
    return false;
  } else {
    if (ua.match(/Android/i) != null) {
      return ua.match(/browser/i) == null;
    } else if (ua.match(/iPhone/i) != null) {
      return ua.match(/safari/i) == null;
    } else {
      return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null);
    }
  }
}
/********判断H5页面是否在web容器中 start*********/
export default {
  name: "AboutHimi",
  data() {
    return {
      url: ''
    }
  },
  created() {
    this.initDicData()
  },
  methods: {
    //初始化字典数据
    initDicData: function () {
      const that = this
      let initData = {}
      initData.dbName = ["aboutHimi"]
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          if(response.data.data.aboutHimi[0].dictionaryValue)
            that.url = response.data.data.aboutHimi[0].dictionaryValue
        })
      })
    },
    leftReturn(){
      //APP传值*******begin*********
      if(openInWebview()){
        //APP传值*******begin*********
        if(checkAndroid()){
          console.log("----checkAndroid()-=true")
          backToAndroid();
        }else if(checkIOS()){
          console.log("----checkAndroid()-=true")
          window.webkit.messageHandlers.closeWindow.postMessage({});
          // console.log("对ios传参，调用IOS关闭页面方法,zheshi");
        }
        //APP传值*******end*********
      }else{
        this.$router.go(-1);
      }
    },
  }
}
</script>

<style scoped>
.nav{
  left: 10px;
  position: fixed;
  z-index: 1;
  height: 20px;
  top: 10px;
}
#url{
  width: 100%;
  height: auto;
  /*overflow:hidden;*/
}
#url img{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  overflow-x: hidden;
  display: block;
  min-width: 100%;
  min-height: 100%;
  transform:translate(-50%,-50%);
}
</style>